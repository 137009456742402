import React from "react";
import Car_card from "./Car_card";
import { useNavigate } from "react-router-dom";

const Car_list = (props) => {
  const cars = props.cars;
  const navigate = useNavigate();

  const handleCarClick = (car) => {
    const carData = encodeURIComponent(JSON.stringify(car));
    const url = `/result?car=${carData}`;
    window.open(url, '_blank');
  };

  return (
    <div className="car_list">
      {cars.map((car) => (
        <div key={car.id} className="car-preview" onClick={() => handleCarClick(car)}>
          <Car_card car={car} />
        </div>
      ))}
    </div>
  );
}

export default Car_list;
