import { useState, forwardRef } from "react";
import { ReactComponent as SearchIcon } from './icons/search-magnifying-glass-svgrepo-com.svg';
import { ReactComponent as SearchIconAI } from './icons/stars-svgrepo-com.svg';

const SearchBar = forwardRef(({ onSearch, useAISearch }, ref) => {
    const [query, setQuery] = useState("");

    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onSearch(query);
        }
    };

    return (
        <div className={`search-bar ${useAISearch ? "ai-search" : ""}`}>
            <input
                ref={ref} // Aquí se pasa la referencia al input de búsqueda
                type="search"
                placeholder={useAISearch ? "Busco coches baratos de menos de 10.000€ y de 5 plazas" : "Marca, modelo..."}
                value={query}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                className={useAISearch ? "ai-input" : ""}
            />
            <button
                type="button"
                onClick={() => onSearch(query)}
                className={useAISearch ? "ai-button" : ""}
            >
                {useAISearch ? <SearchIconAI /> : <SearchIcon />}
            </button>
        </div>
    );
});

export default SearchBar;
