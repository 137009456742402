import React, { useState, useEffect } from 'react';
import { ReactComponent as FilterIcon } from './icons/filter-svgrepo-com.svg';
import { ReactComponent as OrderIcon } from './icons/sort-amount-down-svgrepo-com.svg';

const Center_header = (props) => {
    const { cars, total, order, onOrderChange, onToggleFilters } = props;
    const [isFocused, setIsFocused] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // Función para formatear números con puntos de miles
    const formatNumberWithThousandsSeparators = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    // Formatea el total con puntos de miles
    const formattedTotal = formatNumberWithThousandsSeparators(total);

    const handleOrderChange = (event) => {
        onOrderChange(event.target.value);
        setIsDropdownVisible(false); // Oculta el dropdown después de seleccionar en móviles
    };

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="center_header">
            <div className="car_count">
                {formattedTotal} <span className="smaller-text">coches encontrados</span>
            </div>

            <div className="filter_order">
                {isMobile ? (
                    <>
                        {/* Botón de filtros en móvil a la izquierda */}
                        <button className="toggle-filters-btn" onClick={onToggleFilters}>
                            <FilterIcon className="filter-icon" />Filtrar
                        </button>
                        
                        <div className={`select-wrapper ${isFocused || order ? 'focused' : ''}`}>
                            <button className="order-button" onClick={toggleDropdown}>
                                <OrderIcon className="order-icon" />Ordenar
                            </button>
                            {isDropdownVisible && (
                                <div className="dropdown-options">
                                    <div onClick={() => handleOrderChange({ target: { value: 'relevantes' } })}>Más relevantes</div>
                                    <div onClick={() => handleOrderChange({ target: { value: 'nuevos' } })}>Más nuevos</div>
                                    <div onClick={() => handleOrderChange({ target: { value: 'antiguos' } })}>Más antiguos</div>
                                    <div onClick={() => handleOrderChange({ target: { value: 'mas_km' } })}>Más km</div>
                                    <div onClick={() => handleOrderChange({ target: { value: 'menos_km' } })}>Menos km</div>
                                    <div onClick={() => handleOrderChange({ target: { value: 'mas_caros' } })}>Más caros</div>
                                    <div onClick={() => handleOrderChange({ target: { value: 'mas_baratos' } })}>Más baratos</div>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div className={`select-wrapper ${isFocused || order ? 'focused' : ''}`}>
                        <label htmlFor="order" className="order-placeholder">Ordenar</label>
                        <select
                            id="order"
                            name="order"
                            value={order}
                            onChange={handleOrderChange}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                        >
                            <option value="relevantes">Más relevantes</option>
                            <option value="nuevos">Más nuevos</option>
                            <option value="antiguos">Más antiguos</option>
                            <option value="mas_km">Más km</option>
                            <option value="menos_km">Menos km</option>
                            <option value="mas_caros">Más caros</option>
                            <option value="mas_baratos">Más baratos</option>
                        </select>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Center_header;
