import React from 'react';

const Filters_header = ({ filters, onRemoveFilter, onRemoveAllFilters }) => {
    const filterEntries = Object.entries(filters);

    if (filterEntries.length === 0) {
        return null; // No se muestra nada si no hay filtros seleccionados
    }

    return (
        <div className="filters-header">
            {filterEntries.map(([key, value]) => (
                <div key={key} className="filter-item">
                    {value.toString()}
                    <button className="remove-filter" onClick={() => onRemoveFilter(key)}>
                        &times;
                    </button>
                </div>
            ))}
            {filterEntries.length > 1 && (
                <div className="filter-item-remove-all">
                    <button className="remove-all-filters" onClick={onRemoveAllFilters}>
                        borrar filtros
                    </button>
                </div>
            )}
        </div>
    );
};

export default Filters_header;
