import React from 'react';
import CloseIcon from './icons/close-md-svgrepo-com.svg';
import icon_es from './icons/icon_flags/flag-es-svgrepo-com.svg';
import icon_pt from './icons/icon_flags/flag-pt-svgrepo-com.svg';
import icon_de from './icons/icon_flags/flag-de-svgrepo-com.svg';
import icon_uk from './icons/icon_flags/flag-gb-svgrepo-com.svg';
import icon_fr from './icons/icon_flags/flag-wf-svgrepo-com.svg';
import icon_it from './icons/icon_flags/flag-it-svgrepo-com.svg';
import icon_be from './icons/icon_flags/flag-be-svgrepo-com.svg';
import icon_nl from './icons/icon_flags/flag-nl-svgrepo-com.svg';

const RegionSelector = ({ onClose, onSearch }) => {
  return (
    <div className="advanced-filters">
      <div className="filter-header">
        <h2 className='titulo-destacado'>Seleccione una región</h2>
        <button className="close-button" onClick={onClose}>
          <img src={CloseIcon} alt="Cerrar" />
        </button>
      </div>
      <div className="filter-flag">
        <img src={icon_de} alt="Alemania" />
        <h3>Alemania</h3>
      </div>
      <div className="filter-flag">
        <img src={icon_be} alt="Italia" />
        <h3>Bélgica</h3>
      </div>
      <div className="filter-flag">
        <img src={icon_es} alt="España" />
        <h3>España</h3>
      </div>
      <div className="filter-flag">
        <img src={icon_fr} alt="Francia" />
        <h3>Francia</h3>
      </div>
      <div className="filter-flag">
        <img src={icon_it} alt="Italia" />
        <h3>Italia</h3>
      </div>
      <div className="filter-flag">
        <img src={icon_nl} alt="Italia" />
        <h3>Paises Bajos</h3>
      </div>
      <div className="filter-flag">
        <img src={icon_pt} alt="Portugal" />
        <h3>Portugal</h3>
      </div>
      <div className="filter-flag">
        <img src={icon_uk} alt="Italia" />
        <h3>Reino Unido</h3>
      </div>
    </div>
  );
}

export default RegionSelector;
