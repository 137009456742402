import { useNavigate } from 'react-router-dom';
import sellcar_img from './blank.png';
import icon1 from './icons/car-auto-svgrepo-com.svg';
import icon2 from './icons/chart-bar-vertical-01-svgrepo-com.svg';
import icon3 from './icons/clock-svgrepo-com.svg';


function Insurance_page() {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/results');
        window.scrollTo({ top: 0});
      }

    return (
        <div className="sellcar_page">
            <div className="sellcar_page-body">
                <div className="text-content-sellcar">
                    <h1>Comparador de <br /><span className="titulo-destacado">seguros</span> de coche</h1>
                    <p>Encuentra el seguro de coche que más se<br />adapte a tus necesidades.</p>
                    <button onClick={handleClick}>Calcula tu seguro</button>
                </div>
                <div className="image-content-sellcar">
                    <img src={sellcar_img} alt="Car"></img>
                </div>
            </div>
            <div className="sellcar_page-body2">
                <div className="sellcar_page-body2-content">
                    <h2>Ventajas</h2>
                    <div className="sellcar_page-body2-content-container">
                        <div className="sellcar_page-body2-content-container-column">
                            <img src={icon1} alt="icon1" className="sellcar_icon" />
                            <h3>Ahorra más del 50% en el precio de tu seguro de coche.</h3>
                            <p>Utilizando el comparador de seguros, puedes reducir significativamente el coste al contratar o renovar tu seguro de coche.</p>
                        </div>
                        <div className="sellcar_page-body2-content-container-column">
                            <img src={icon2} alt="icon1" className="sellcar_icon" />
                            <h3>Compara precios de 30 aseguradoras al mismo tiempo.</h3>
                            <p>En coches.com tienes acceso a todas las principales aseguradoras de coches, simplificando el proceso de cambio de seguro con nuestro comparador.</p>
                        </div>
                        <div className="sellcar_page-body2-content-container-column">
                            <img src={icon3} alt="icon1" className="sellcar_icon" />
                            <h3>Calcula tu cuota en solo dos minutos.</h3>
                            <p>Un proceso rápido y sencillo para obtener una cotización de seguro entre más de 30 compañías aseguradoras y ahorra hasta 600€ en el seguro de tu coche.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="sellcar_page-body2">
                <div className="sellcar_page-body2-content">
                    <h2>¿Cuáles son las ventajas?</h2>
                </div>
            </div>*/}
        </div>
    );
}
  
export default Insurance_page;
 