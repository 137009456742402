import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Audi_icon from './brand_icons/audi.svg';
import Bmw from './brand_icons/bmw.svg';
import Byd from './brand_icons/BYD.webp';
import Citroen from './brand_icons/citroen.svg';
import Chevrolet from './brand_icons/chevrolet.svg';
import Ferrari from './brand_icons/ferrari.svg';
import Ford from './brand_icons/ford.svg';
import Infiniti from './brand_icons/infiniti.svg';
import Jaguar from './brand_icons/jaguar.svg';
import Land_rover from './brand_icons/land-rover.svg';
import Mitsubishi from './brand_icons/mitsubishi.svg';
import Opel from './brand_icons/opel.svg';
import Porsche from './brand_icons/porsche.svg';
import Toyota from './brand_icons/toyota.svg';
import Volkswagen from './brand_icons/volkswagen.svg';
import Volvo from './brand_icons/volvo.svg';
import Alfa_romeo from './brand_icons/alfa-romeo-alt.svg';
import Bentley from './brand_icons/bentley.svg';
import Hyundai from './brand_icons/hyundai.svg';
import Jeep from './brand_icons/jeep.svg';
import Lamborghini from './brand_icons/lamborghini.svg';
import Seat from './brand_icons/seat.svg';
import Tesla from './brand_icons/tesla.svg';
import Lotus from './brand_icons/lotus.svg';
import Mazda from './brand_icons/mazda.svg';
import Rolls_royce from './brand_icons/rolls-royce.svg';
import Honda from './brand_icons/honda.svg';
import Mclaren from './brand_icons/mclaren.svg';
import Mini from './brand_icons/mini.svg';
import Dodge from './brand_icons/dodge.svg';
import Smart from './brand_icons/smart.svg';
import Subaru from './brand_icons/subaru.svg';
import Suzuki from './brand_icons/suzuki.svg';
import Mercedes from './brand_icons/mercedes.svg';
import Abarth from './brand_icons/abarth.svg';
import Corvette from './brand_icons/corvette.svg';
import Cupra from './brand_icons/cupra.svg';
import Dacia from './brand_icons/dacia.svg';

const BrandsMenu = () => {
    const [showAll, setShowAll] = useState(false);
    const navigate = useNavigate();

    const handleBrandClick = (brandName) => {
        navigate(`/results?query=${encodeURIComponent(brandName)}`);
    };

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const brandIcons = [
        { name: 'Abarth', icon: Abarth },
        { name: 'Alfa Romeo', icon: Alfa_romeo },
        { name: 'Audi', icon: Audi_icon },
        { name: 'Bentley', icon: Bentley },
        { name: 'BMW', icon: Bmw },
        { name: 'BYD', icon: Byd },
        { name: 'Cadillac', icon: Audi_icon },
        { name: 'Chevrolet', icon: Chevrolet },
        { name: 'Chrysler', icon: Audi_icon },
        { name: 'Citroen', icon: Citroen },
        { name: 'Corvette', icon: Corvette },
        { name: 'Cupra', icon: Cupra },
        { name: 'Dacia', icon: Dacia },
        { name: 'Dodge', icon: Dodge },
        { name: 'DS', icon: Audi_icon },
        { name: 'Ferrari', icon: Ferrari },
        { name: 'Fiat', icon: Audi_icon },
        { name: 'Ford', icon: Ford },
        { name: 'Honda', icon: Honda },
        { name: 'Hyundai', icon: Hyundai },
        { name: 'Infiniti', icon: Infiniti },
        { name: 'Jaguar', icon: Jaguar },
        { name: 'Jeep', icon: Jeep },
        { name: 'Kia', icon: Audi_icon },
        { name: 'Lamborghini', icon: Lamborghini },
        { name: 'Lancia', icon: Audi_icon },
        { name: 'Land Rover', icon: Land_rover },
        { name: 'Lexus', icon: Audi_icon },
        { name: 'Lotus', icon: Lotus },
        { name: 'Lynk & Co', icon: Audi_icon },
        { name: 'Maserati', icon: Audi_icon },
        { name: 'Mazda', icon: Mazda },
        { name: 'Mclaren', icon: Mclaren },
        { name: 'Mercedes', icon: Mercedes },
        { name: 'MG', icon: Audi_icon },
        { name: 'Mini', icon: Mini },
        { name: 'Mitsubishi', icon: Mitsubishi },
        { name: 'Nissan', icon: Audi_icon },
        { name: 'Opel', icon: Opel },
        { name: 'Peugeot', icon: Audi_icon },
        { name: 'Polestar', icon: Audi_icon },
        { name: 'Porsche', icon: Porsche },
        { name: 'Renault', icon: Audi_icon },
        { name: 'Rolls Royce', icon: Rolls_royce },
        { name: 'Seat', icon: Seat },
        { name: 'Skoda', icon: Audi_icon },
        { name: 'Smart', icon: Smart },
        { name: 'Ssangyong', icon: Audi_icon },
        { name: 'Subaru', icon: Subaru },
        { name: 'Suzuki', icon: Suzuki },
        { name: 'Tesla', icon: Tesla },
        { name: 'Toyota', icon: Toyota },
        { name: 'Volkswagen', icon: Volkswagen },
        { name: 'Volvo', icon: Volvo }
    ];

    return (
        <div className="brands_menu">
            <h3 className="titulo-marcas">Busca por <span className="titulo-destacado">marca</span></h3>
            <div className={`grid-columns-brands ${showAll ? 'show-all' : ''}`}>
                {brandIcons.slice(0, showAll ? brandIcons.length : 20).map((brand, index) => (
                    <button
                        key={index}
                        className="grid-title-brands"
                        onClick={() => handleBrandClick(brand.name)}
                    >
                        <img src={brand.icon} alt={`${brand.name} icon`} className="icon-brand" />
                        {brand.name}
                    </button>
                ))}
            </div>
            <button className="ver-todo-btn" onClick={toggleShowAll}>
                {showAll ? '- Ver menos' : '+ Ver más'}
            </button>
        </div>
    );
};

export default BrandsMenu;
