import Filters from "./Filters";
import Car_list from "./Car_list";
import { useState, useEffect, useRef } from "react";
import SearchBar2 from "./SearchBar3";
import Center_header from "./Center_header";
import Filters_header from "./Filters_header";
import { useLocation, useNavigate } from 'react-router-dom';
import useFetch from "./useFetch";
import { ClipLoader } from 'react-spinners';

const Results_page = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const initialQuery = searchParams.get('query') || '';
    const initialPage = parseInt(searchParams.get('page')) || 1;
    const initialOrder = searchParams.get('order') || 'relevantes';
    const initialFilters = searchParams.get('filters') ? JSON.parse(searchParams.get('filters')) : {};

    const [query, setQuery] = useState(initialQuery);
    const [page, setPage] = useState(initialPage);
    const [order, setOrder] = useState(initialOrder);
    const [filters, setFilters] = useState(initialFilters);
    const [resultsPerPage] = useState(20);
    const searchInputRef = useRef(null);
    const [filtersVisible, setFiltersVisible] = useState(false); // Estado para mostrar/ocultar filtros

    const fetchUrl = query 
        ? `http://3.95.6.146:8080/new_search/?data=${encodeURIComponent(query)}${Object.keys(filters).length ? `&filters=${encodeURIComponent(JSON.stringify(filters))}` : ''}&page=${page}&results_per_page=${resultsPerPage}&order=${order}`
        : `http://3.95.6.146:8080/new_search/?${Object.keys(filters).length ? `filters=${encodeURIComponent(JSON.stringify(filters))}` : ''}&page=${page}&results_per_page=${resultsPerPage}&order=${order}`;

    const { data, isPending, error } = useFetch(fetchUrl);

    const cleanFilters = (filters) => {
        const cleanedFilters = {};
        Object.keys(filters).forEach(key => {
            if (filters[key] && filters[key].length > 0) {
                cleanedFilters[key] = filters[key];
            }
        });
        return cleanedFilters;
    };
    
    const handleSearch = (newQuery, newFilters = filters) => {
        const cleanedFilters = cleanFilters(newFilters);
        setQuery(newQuery);
        setFilters(cleanedFilters);
        setPage(1);
        let url = `/results?query=${encodeURIComponent(newQuery)}&page=1&order=${order}`;
        if (Object.keys(cleanedFilters).length) {
            url += `&filters=${encodeURIComponent(JSON.stringify(cleanedFilters))}`;
        }
        navigate(url);
    };

    const handleOrderChange = (newOrder) => {
        const cleanedFilters = cleanFilters(filters);
        setOrder(newOrder);
        setPage(1);
        let url = `/results?query=${encodeURIComponent(query)}&page=1&order=${newOrder}`;
        if (Object.keys(cleanedFilters).length) {
            url += `&filters=${encodeURIComponent(JSON.stringify(cleanedFilters))}`;
        }
        navigate(url);
    };

    const handleFilterChange = (newFilters) => {
        const cleanedFilters = cleanFilters(newFilters);
        setFilters(cleanedFilters);
        setPage(1);
        let url = `/results?query=${encodeURIComponent(query)}&page=1&order=${order}`;
        if (Object.keys(cleanedFilters).length) {
            url += `&filters=${encodeURIComponent(JSON.stringify(cleanedFilters))}`;
        }
        navigate(url);
    };

    const handleRemoveFilter = (filterKey) => {
        const newFilters = { ...filters };
        delete newFilters[filterKey];
        handleFilterChange(newFilters);
    };

    const handleRemoveAllFilters = () => {
        handleFilterChange({});
    };

    const handlePageChange = (newPage) => {
        const cleanedFilters = cleanFilters(filters);
        setPage(newPage);
        let url = `/results?query=${encodeURIComponent(query)}&page=${newPage}&order=${order}`;
        if (Object.keys(cleanedFilters).length) {
            url += `&filters=${encodeURIComponent(JSON.stringify(cleanedFilters))}`;
        }
        navigate(url);
        window.scrollTo({ top: 0 });
    };

    useEffect(() => {
        // Sólo hacer focus si el ancho de pantalla es mayor a 768px (escritorio o tablet en modo horizontal)
        if (searchInputRef.current && window.innerWidth > 768) {
            searchInputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const newQuery = searchParams.get('query') || '';
        const newPage = parseInt(searchParams.get('page')) || 1;
        const newOrder = searchParams.get('order') || 'relevantes';
        const newFilters = searchParams.get('filters') ? JSON.parse(searchParams.get('filters')) : {};
        if (newQuery !== query) setQuery(newQuery);
        if (newPage !== page) setPage(newPage);
        if (newOrder !== order) setOrder(newOrder);
        if (JSON.stringify(newFilters) !== JSON.stringify(filters)) setFilters(newFilters);
    }, [location.search]);

    const totalPages = data ? Math.ceil(data.total / resultsPerPage) : 0;

    const toggleFilters = () => {
        setFiltersVisible(!filtersVisible);
    };

    return (
        <div className="results_page">
            <div className="results_page-content">
                <aside className={`left ${filtersVisible ? 'visible' : ''}`}>
                    <Filters filters={filters} onFilterChange={handleFilterChange} onClose={toggleFilters} onRemoveAllFilters={handleRemoveAllFilters} />
                    <div className="results_page_banner">
                        <div className="results_page_banner2">
                            <p>Anunci</p>
                        </div>
                    </div>
                </aside>
                <main className="center">
                    <SearchBar2 onSearch={handleSearch} query={query} ref={searchInputRef} />
                    {isPending && (
                        <div className="loading-container">
                            <ClipLoader color="#123abc" loading={true} />
                            <div className="loading-text"></div>
                        </div>
                    )}
                    {error && !isPending && (
                        <div className="error-message">
                            <p>There was an error loading the data: {error}</p>
                        </div>
                    )}
                    {data && data.cars && !isPending && !error && (
                        <>
                            <Center_header cars={data.cars} total={data.total} order={order} onOrderChange={handleOrderChange} onToggleFilters={toggleFilters} />
                            <Filters_header filters={filters} onRemoveFilter={handleRemoveFilter} onRemoveAllFilters={handleRemoveAllFilters} />
                            <Car_list cars={data.cars} />
                        </>
                    )}
                    {data && data.total > 0 && (
                        <div className="pagination">
                            <button className="prev-button" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                                &lt;
                            </button>
                            {page > 3 && (
                                <>
                                    <button onClick={() => handlePageChange(1)}>1</button>
                                    <span>...</span>
                                </>
                            )}
                            {page > 2 && <button onClick={() => handlePageChange(page - 2)}>{page - 2}</button>}
                            {page > 1 && <button onClick={() => handlePageChange(page - 1)}>{page - 1}</button>}
                            <button className="current-page" disabled>{page}</button>
                            {page < totalPages && <button onClick={() => handlePageChange(page + 1)}>{page + 1}</button>}
                            {page < totalPages - 1 && <button onClick={() => handlePageChange(page + 2)}>{page + 2}</button>}
                            {page < totalPages - 2 && (
                                <>
                                    <span>...</span>
                                    <button onClick={() => handlePageChange(totalPages)}>
                                        {totalPages}
                                    </button>
                                </>
                            )}
                            <button className="next-button" onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
                                &gt;
                            </button>
                        </div>
                    )}
                </main>
                <aside className="right mobile-hidden">
                    <div className="results_page_banner">
                        <div className="results_page_banner2">
                            <p>Anunci</p>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    );
};

export default Results_page;
