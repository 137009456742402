import Navbar from './Navbar';
import Home from './Home';
import Results_page from './Results_page'
import Result_page from './Result_page'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './Footer';
import SellCar_page from './SellCar_page';
import CarHistory_page from './CarHistory_page';
import DarkModeToggle from './DarkModeToggle';
import Insurance_page from './Insurace_page';


function App() {

  return (
      <Router>
        <div className="App">
          <Navbar />
          <div className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/results" element={<Results_page />} />
              <Route path="/sellcar" element={<SellCar_page />} />
              <Route path="/carhistory" element={<CarHistory_page />} />
              <Route path="/insurance" element={<Insurance_page />} />
              <Route path="/result" element={<Result_page />} />
            </Routes>
          </div>
          <DarkModeToggle/>
          <p className="version">v1.14</p> 
          <Footer />
        </div>
      </Router>
  );
}

export default App;
