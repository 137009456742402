import React, { useState, useRef, useEffect } from 'react';
import arrowIcon from './icons/caret-down-md-svgrepo-com.svg';
import crossIcon from './icons/close-md-svgrepo-com.svg';

const MultiSelect = ({ options, placeholder, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filter, setFilter] = useState('');
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setFilter('');
    }
  };

  const handleSelect = (option) => {
    const isSelected = selectedOptions.includes(option.label);
    let newSelectedOptions;
    if (isSelected) {
      newSelectedOptions = selectedOptions.filter(item => item !== option.label);
    } else {
      newSelectedOptions = [...selectedOptions, option.label];
    }
    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const clearSelection = (e) => {
    e.stopPropagation();
    setSelectedOptions([]);
    onChange([]);
  };

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div ref={containerRef} className="multi-select-container">
      <div 
        className={`multi-select-control ${selectedOptions.length > 0 ? 'has-selection' : ''}`}
        onClick={handleToggle}
      >
        <div className="multi-select-placeholder">
          {selectedOptions.length > 0 ? selectedOptions.join(', ') : placeholder}
        </div>
        <div className="multi-select-controls-right">
          {selectedOptions.length > 0 && (
            <div className="clear-selection" onClick={clearSelection}>
              <img src={crossIcon} alt="Clear Selection" className="cross-icon" />
            </div>
          )}
          <div className="multi-select-arrow">
            <img src={arrowIcon} alt="Arrow" className={`arrow-icon ${isOpen ? 'open' : 'closed'}`} />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="multi-select-menu">
          <input
            ref={inputRef}
            type="text"
            className="multi-select-input"
            placeholder="Escriba para filtrar..."
            value={filter}
            onChange={handleFilterChange}
          />
          {filteredOptions.map(option => (
            <div
              key={option.value}
              className={`multi-select-option ${selectedOptions.includes(option.label) ? 'selected' : ''}`}
              onClick={() => handleSelect(option)}
            >
              {option.icon && (
                <img src={option.icon} alt={option.label} className="multi-select-option-icon" />
              )}
              <span className={`multi-select-option-label ${selectedOptions.includes(option.label) ? 'selected' : ''}`}>
                {option.label}
              </span>
              <input
                type="checkbox"
                checked={selectedOptions.includes(option.label)}
                onChange={() => handleSelect(option)}
                className="multi-select-checkbox"
              />
              <span className="custom-checkbox"></span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
