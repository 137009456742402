import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Result_page = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [car, setCar] = useState({});
    const [query, setQuery] = useState('');
    const [siteName, setSiteName] = useState('');

    const {
        car_brand: marca,
        car_model: modelo,
        car_version: version,
        car_price: precio,
        car_img: imagen,
        car_mileage: kilometraje,
        car_fuel: combustible,
        car_gearbox: transmision,
        car_year: año,
        car_power: potencia,
        car_cc: cilindrada,
        car_doors: puertas,
        car_seats: asientos,
        car_color: color,
        car_location: ubicacion,
        car_url: url,
        car_date: fecha  // assuming you have car_date in your car object
    } = car;

    const formatNumber = (number) => {
        return number.toLocaleString('de-DE'); // Usamos 'de-DE' para formato europeo (1.234.567,89)
    };

    const carDetails = [
        { title: "Marca", value: marca },
        { title: "Modelo", value: modelo },
        { title: "Versión", value: version },
        { title: "Precio", value: precio ? `${formatNumber(precio)} €` : null },
        { title: "Año", value: año },
        { title: "Kilometraje", value: kilometraje ? `${formatNumber(kilometraje)} km` : null },
        { title: "Combustible", value: combustible },
        { title: "Transmisión", value: transmision },
        { title: "Potencia", value: potencia ? `${formatNumber(potencia)} CV` : null },
        { title: "Cilindrada", value: cilindrada },
        { title: "Puertas", value: puertas },
        { title: "Asientos", value: asientos },
        { title: "Color", value: color },
        { title: "Ubicación", value: ubicacion }
    ];

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const carData = queryParams.get('car');
        const searchQuery = queryParams.get('query');
        if (carData) {
            setCar(JSON.parse(decodeURIComponent(carData)));
        }
        if (searchQuery) {
            setQuery(searchQuery);
        }
    }, [location.search]);

    useEffect(() => {
        if (url) {
            const regex = /https?:\/\/(www\.)?([\w-]+\.\w+)\//;
            const match = url.match(regex);
            if (match && match[2]) {
                setSiteName(match[2]);
            }
        }
    }, [url]);

    const handleBackClick = () => {
        navigate(`/results?query=${encodeURIComponent(query)}`);
        /*navigate(-1);*/
    };

    return (
        <div className="result_page_full">
            <div className="result_page">
                <div className="result_page_content">
                    {/* Botón de regreso */}
                    {/*<button className="back_button" onClick={handleBackClick}>&lt; Volver a los resultados</button>*/}

                    {/* Título */}
                    <h1>{`${marca} ${modelo} ${version}`}</h1>

                    <div className="result_page_main">
                        {/* Sección de imagen y datos del coche */}
                        <div className="result_page_top">
                            {/* Imagen del coche */}
                            <div className="result_page_image">
                                <img src={imagen} alt={`${marca} ${modelo}`} />
                            </div>

                            {/* Datos del coche */}
                            <div className="result_page_details">
                                <div className="result_page_details_price">
                                    {precio ? `${formatNumber(precio)} €` : ''}
                                </div>
                                <div className="result_page_details_grid">
                                    <div>
                                        <div>Año</div>
                                        <div>{año}</div>
                                        <div>Kilometraje</div>
                                        <div>{kilometraje ? `${formatNumber(kilometraje)} km` : ''}</div>
                                    </div>
                                    <div>
                                        <div>Transmisión</div>
                                        <div>{transmision}</div>
                                        <div>Combustible</div>
                                        <div>{combustible}</div>
                                    </div>
                                </div>
                                <div className="result_page_details_location">
                                    {ubicacion}
                                </div>
                            </div>
                        </div>

                        {/* Sección inferior con título más pequeño y subtítulo */}
                        <div className="result_page_bottom">
                            <h2>Contacta con el vendedor</h2>
                            <div className="contact_button_container">
                                <a href={url} className="contact_button">{siteName}</a>
                            </div>
                            <h2>Datos del coche</h2>
                            <table className="car_details_table">
                                <tbody>
                                    {carDetails.filter(detail => detail.value && detail.value !== "N/D").map((detail, index) => (
                                        <tr key={index}>
                                            <td>{detail.title}</td>
                                            <td className="car_detail_value">{detail.value}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="result_page_banner2">
                            <div className="banner">
                                <p>Anunci</p>
                                {/* Puedes reemplazar esto con un <img> o un componente de anuncio */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Banner vertical */}
                <div className="result_page_banner">
                    <div className="banner">
                        <p>Anunci</p>
                        {/* Puedes reemplazar esto con un <img> o un componente de anuncio */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Result_page;
