import { useState, useEffect } from "react";
import MultiSelect from './MultiSelect2';
import OneSelect from './OneSelect2';

const Filters = ({ filters, onFilterChange, onClose, onRemoveAllFilters }) => {
    const [internalFilters, setInternalFilters] = useState(filters);
    const [filterOptions, setFilterOptions] = useState({
        car_brand: [],
        car_model: [],
        car_location_country: [],
        car_gearbox: [],
        car_fuel: [],
        car_doors: [],
        car_seats: [],
        car_color: []
    });

    useEffect(() => {
        setInternalFilters(filters);
    }, [filters]);

    useEffect(() => {
        const fetchFilterOptions = async () => {
            try {
                const options = await getFilterOptions(internalFilters);
                setFilterOptions(options);
            } catch (error) {
                console.error('Error fetching filter options:', error);
                setFilterOptions({
                    car_brand: [],
                    car_model: [],
                    car_location_country: [],
                    car_gearbox: [],
                    car_fuel: [],
                    car_doors: [],
                    car_seats: [],
                    car_color: []
                });
            }
        };

        fetchFilterOptions();
    }, [internalFilters]);

    const handleFilterChange = (selectedOptions, { name }) => {
        const newFilters = { ...internalFilters, [name]: selectedOptions };
        setInternalFilters(newFilters);
        onFilterChange(newFilters);
    };

    const renderMultiSelect = (name, value, options, placeholder) => (
        <div className="select-container">
            {internalFilters[name] && internalFilters[name].length > 0 && <label className="placeholder-label">{placeholder}</label>}
            <MultiSelect
                options={options}
                value={value}
                onChange={selected => handleFilterChange(selected, { name })}
                placeholder={placeholder}
            />
        </div>
    );

    const renderOneSelect = (name, value, options, placeholder) => (
        <div className="select-container">
            {internalFilters[name] && internalFilters[name].length > 0 && <label className="placeholder-label">{placeholder}</label>}
            <OneSelect
                options={options}
                value={value}
                onChange={selected => handleFilterChange(selected, { name })}
                placeholder={placeholder}
            />
        </div>
    );

    return (
        <div className="filters">
            <div className="filters-top">
                <button className="remove-all-filters-button" onClick={onRemoveAllFilters}>Borrar filtros</button>
                <button className="close-filters-button" onClick={onClose}>✕</button>
            </div>
            <div className="filter">{renderMultiSelect("car_location_country", internalFilters.car_location_country, filterOptions.car_location_country, "País")}</div>
            <div className="filter">{renderMultiSelect("car_brand", internalFilters.car_brand, filterOptions.car_brand, "Marca")}</div>
            <div className="filter">{renderMultiSelect("car_model", internalFilters.car_model, filterOptions.car_model, "Modelo")}</div>
            <div className="filter">{renderOneSelect("car_gearbox", internalFilters.car_gearbox, filterOptions.car_gearbox, "Transmisión")}</div>
            <div className="filter">{renderMultiSelect("car_fuel", internalFilters.car_fuel, filterOptions.car_fuel, "Combustible")}</div>
            <div className="filter">{renderOneSelect("car_doors", internalFilters.car_doors, filterOptions.car_doors, "Puertas")}</div>
            <div className="filter">{renderOneSelect("car_seats", internalFilters.car_seats, filterOptions.car_seats, "Plazas")}</div>
            <div className="filter">{renderMultiSelect("car_color", internalFilters.car_color, filterOptions.car_color, "Color")}</div>
        </div>
    );
};

export default Filters;


const getFilterOptions = async (activeFilters) => {
    try {
        const activeFiltersString = JSON.stringify(activeFilters, (key, value) => {
            if (Array.isArray(value) && value.length === 1) {
                return value[0];
            }
            return value;
        });

        const baseUrl = "http://3.95.6.146:8080/get_filters/";
        const url = `${baseUrl}?active_filters=${activeFiltersString}`;

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const options = {};
        for (const [key, value] of Object.entries(data)) {
            options[key] = Object.keys(value).map(option => ({ value: option, label: `${option} (${value[option]})` }));
        }
        return options;
    } catch (error) {
        console.error('Error fetching filter options:', error);
        return {
            car_brand: [],
            car_model: [],
            car_location_country: [],
            car_gearbox: [],
            car_fuel: [],
            car_doors: [],
            car_seats: [],
            car_color: []
        };
    }
};
