import React from 'react';
import logo from './logotest.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer">
            <div className="footer-columns">
                
                <div className="footer-column">
                    <img src={logo} alt="Logo" className="footer-logo" />
                </div>
                
                <div className="footer-column">
                    <h4 className="footer-title">Enlaces</h4>
                    <ul className="footer-links">
                        <li><Link to="/results" className="footer-link">Vender coche</Link></li>
                        <li><Link to="/" className="footer-link">Historial coche</Link></li>
                        <li><Link to="/" className="footer-link">Seguros coche</Link></li>
                        <li><a href="/" className="footer-link">Noticias</a></li>
                    </ul>
                </div>

                <div className="footer-column">
                    <h4 className="footer-title">Legal</h4>
                    <ul className="footer-links">
                        <li><Link to="/results" className="footer-link">Cookies</Link></li>
                        <li><Link to="/" className="footer-link">Política de privacidad</Link></li>
                        <li><Link to="/" className="footer-link">Términos y condiciones</Link></li>
                    </ul>
                </div>
                
                <div className="footer-column">
                    <h4 className="footer-title">Contacto</h4>
                    <p className="footer-contact">hello@spotmyauto.com</p>
                </div>
            </div>
            <div className="footer-copyright">
                &copy; {currentYear} SpotMyAuto. Todos los derechos reservados.
            </div>
        </footer>
    );
};

export default Footer;