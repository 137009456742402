import React, { useState, useRef, useEffect } from 'react';
import arrowIcon from './icons/caret-down-md-svgrepo-com.svg';

const OneSelect2 = ({ options = [], placeholder, onChange, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value || null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Update local state when the value prop changes
    setSelectedOption(value);
  }, [value]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    setSelectedOption(option.label);
    setIsOpen(false);
    // Pass only the text label to onChange
    onChange(option.label.split(' ')[0]);
  };

  const clearSelection = (e) => {
    e.stopPropagation();
    setSelectedOption(null);
    onChange(null);
    setIsOpen(false); // Close the dropdown
  };

  return (
    <div ref={containerRef} className={`multi-select-container2 ${isOpen ? 'is-open' : ''}`}>
      <div 
        className={`multi-select-control2 ${selectedOption ? 'has-selection' : ''}`}
        onClick={handleToggle}
      >
        <div className={`multi-select-placeholder ${selectedOption ? 'placeholder-selected' : 'placeholder-default'}`}>
          {selectedOption || placeholder}
        </div>
        <div className="multi-select-arrow2">
          <img src={arrowIcon} alt="Arrow" className={`arrow-icon2 ${isOpen ? 'open' : 'closed'}`} />
        </div>
      </div>
      {isOpen && (
        <div className="multi-select-menu2">
          <div className="multi-select-options">
            {capitalizeAndSortOptions(options).map(option => (
              <div
                key={option.value}
                className={`multi-select-option ${selectedOption === option.label ? 'selected' : ''}`}
                onClick={() => handleSelect(option)}
              >
                <span className={`multi-select-option-label ${selectedOption === option.label ? 'selected' : ''}`}>
                  {option.label}
                </span>
              </div>
            ))}
          </div>
          <div className="multi-select-buttons">
            <button className="clear-all-button" onClick={clearSelection}>
              Borrar Selección
            </button>
            <button className="close-button" onClick={() => setIsOpen(false)}>
              Aceptar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const capitalizeAndSortOptions = (options) => {
  return options
    .map(option => ({
      ...option,
      label: option.label.charAt(0).toUpperCase() + option.label.slice(1).toLowerCase()
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export default OneSelect2;
