import React from 'react';
import logo from './logotest.png';

const Car_card = (props) => {
  const car = props.car;

  const formatPrice = (price) => {
    return price.toLocaleString('de-DE'); // Usamos 'de-DE' para formato europeo (1.234.567,89)
  };

  return (
    <div className="car_card">
      <div className="car-img">
        <img src={car.car_img} alt={`${car.car_brand} ${car.car_model}`} className="car_image" />   
      </div>

      <div className="car-info">
        <span className="car_date">{car.car_date}</span>
        <span className="car_brand_model">{car.car_brand} {car.car_model}</span>
        <span className="car_version">{car.car_version}</span>
        <span className="car_price">{formatPrice(car.car_price)} €</span>
        <span className="car_year_mileage_gearbox_fuel">{car.car_year} | {car.car_mileage} | {car.car_gearbox} | {car.car_fuel}</span>
      </div>
    </div>
  );
}

export default Car_card;
