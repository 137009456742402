import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import SearchBar from "./SearchBar";
import Categories_menu from "./Categories_menu";
import Brands_menu from "./Brands_menu";
import TypeCar_menu from "./TypeCar_menu";
import AdvancedFilters from "./AdvancedFilters";
import ToggleSwitch from "./ToggleSwitch";

function Home() {
    const [query, setQuery] = useState(null);
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
    const [useAISearch, setUseAISearch] = useState(false);
    const navigate = useNavigate();
    const searchInputRef = useRef(null);

    useEffect(() => {
        // Sólo hacer focus si el ancho de pantalla es mayor a 768px (escritorio o tablet en modo horizontal)
        if (searchInputRef.current && window.innerWidth > 768) {
            searchInputRef.current.focus();
        }
    }, []);

    const handleSearch = (query, filters = {}) => {
        if (!query?.trim() && !Object.keys(filters).length) {
            console.log("Search query and filters are empty. No search performed.");
            return;
        }
        setQuery(query);

        let url = `/results?query=${encodeURIComponent(query || '')}`;
        if (Object.keys(filters).length > 0) {
            const filtersString = encodeURIComponent(JSON.stringify(filters));
            url += `&filters=${filtersString}`;
        }
        navigate(url);
        window.scrollTo({ top: 0 });
    };

    const handleClick = () => {
        navigate('/results');
        window.scrollTo({ top: 0 });
    };

    const toggleAdvancedFilters = () => {
        setShowAdvancedFilters(!showAdvancedFilters);
    };

    const handleSwitchChange = () => {
        setUseAISearch(!useAISearch);
    };

    return (
        <div className="home">
            <h1>Encuentra tu coche <span className="titulo-destacado">ideal</span></h1>
            <p>Analizamos centenares de páginas de coches<br />de segunda mano en toda Europa</p>
            <ToggleSwitch checked={useAISearch} onChange={handleSwitchChange} />
            <SearchBar onSearch={handleSearch} ref={searchInputRef} useAISearch={useAISearch} />
            <button onClick={handleClick} className="ver-todo-button">Ver todo</button>
            <button onClick={toggleAdvancedFilters} className="ver-todo-button">Avanzado</button>
            {showAdvancedFilters && <div className="overlay" onClick={toggleAdvancedFilters}></div>}
            {showAdvancedFilters && <AdvancedFilters onClose={toggleAdvancedFilters} onSearch={handleSearch} />}
            <Categories_menu />
            <TypeCar_menu />
            <Brands_menu />
        </div>
    );
}

export default Home;
