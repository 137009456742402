import React, { useState, useRef, useEffect } from 'react';
import arrowIcon from './icons/caret-down-md-svgrepo-com.svg';
import crossIcon from './icons/close-md-svgrepo-com.svg'; // Importa tu icono de cruz SVG

const OneSelect = ({ options, placeholder, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    setSelectedOption(option.label);
    onChange(option.label);
    setIsOpen(false);
  };

  const clearSelection = (e) => {
    e.stopPropagation();
    setSelectedOption(null);
    onChange(null);
  };

  return (
    <div ref={containerRef} className="one-select-container">
      <div 
        className={`one-select-control ${selectedOption ? 'has-selection' : ''}`}
        onClick={handleToggle}
      >
        <div className={`one-select-placeholder ${selectedOption ? 'selected' : ''}`}>
          {selectedOption ? selectedOption : placeholder}
        </div>
        <div className="one-select-controls-right">
          {selectedOption && (
            <div className="clear-selection" onClick={clearSelection}>
              <img src={crossIcon} alt="Clear Selection" className="cross-icon" />
            </div>
          )}
          <div className="one-select-arrow">
            <img src={arrowIcon} alt="Arrow" className={`arrow-icon ${isOpen ? 'open' : 'closed'}`} />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="one-select-menu">
          {options.map(option => (
            <div
              key={option.value}
              className={`one-select-option ${selectedOption === option.label ? 'selected' : ''}`}
              onClick={() => handleSelect(option)}
            >
              <span className={`one-select-option-label ${selectedOption === option.label ? 'selected' : ''}`}>
                {option.label}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OneSelect;
