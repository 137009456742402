import SUV_icon from './type_icons/suv.svg';
import Berlina_icon from './type_icons/berlina.svg';
import Deportivo_icon from './type_icons/deportivo.svg';
import Descapotable_icon from './type_icons/descapotable.svg';
import Furgoneta_icon from './type_icons/furgoneta.svg';
import Monovolumen_icon from './type_icons/monovolumen.svg';
import Urbano_icon from './type_icons/urbano.svg';

const TypeCar_menu = () => {

    return (
      <div className="types_menu">
        <h3 className="titulo-marcas">Busca por <span className="titulo-destacado">tipo de coche</span></h3>
  
        <div className="grid-columns-types">
  
            <div className="grid-item-types">
                <h4 className="grid-title-types">
                    <img src={SUV_icon} alt="Audi icon" className="icon-types" /> SUV y 4x4
                </h4>       
            </div>
            <div className="grid-item-types">
                <h4 className="grid-title-types">
                    <img src={Berlina_icon} alt="Audi icon" className="icon-types" /> Berlina
                </h4>       
            </div>
            <div className="grid-item-types">
                <h4 className="grid-title-types">
                    <img src={Monovolumen_icon} alt="Audi icon" className="icon-types" /> Monovolumen
                </h4>       
            </div>
            <div className="grid-item-types">
                <h4 className="grid-title-types">
                    <img src={Urbano_icon} alt="Audi icon" className="icon-types" /> Urbano
                </h4>       
            </div>
            <div className="grid-item-types">
                <h4 className="grid-title-types">
                    <img src={Deportivo_icon} alt="Audi icon" className="icon-types" /> Deportivo
                </h4>       
            </div>
            <div className="grid-item-types">
                <h4 className="grid-title-types">
                    <img src={Descapotable_icon} alt="Audi icon" className="icon-types" /> Descapotable
                </h4>       
            </div>
            <div className="grid-item-types">
                <h4 className="grid-title-types">
                    <img src={Furgoneta_icon} alt="Audi icon" className="icon-types" /> Furgoneta
                </h4>       
            </div>
        </div>
  
      </div>
    );
  }
    
  export default TypeCar_menu;