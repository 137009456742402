import { useNavigate } from 'react-router-dom';
import sellcar_img from './sellcar_photo.png';
import icon1 from './icons/tag-svgrepo-com.svg';
import icon2 from './icons/calendar-check-svgrepo-com.svg';
import icon3 from './icons/clock-svgrepo-com.svg';


function SellCar_page() {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/results');
        window.scrollTo({ top: 0});
      }

    return (
        <div className="sellcar_page">
            <div className="sellcar_page-body">
                <div className="text-content-sellcar">
                    <h1>Vende tu coche al <br /><span className="titulo-destacado">mejor</span> precio</h1>
                    <p>Colaboramos con el mayor comprador de coches de Europa para<br />que puedas vender tu coche de una forma rápida, fácil y segura.</p>
                    <button onClick={handleClick}>Vender coche</button>
                </div>
                <div className="image-content-sellcar">
                    <img src={sellcar_img} alt="Car"></img>
                </div>
            </div>
            <div className="sellcar_page-body2">
                <div className="sellcar_page-body2-content">
                    <h2>¿Cómo vender mi coche?</h2>
                    <div className="sellcar_page-body2-content-container">
                        <div className="sellcar_page-body2-content-container-column">
                            <img src={icon1} alt="icon1" className="sellcar_icon" />
                            <h3>1. Recibe una oferta real</h3>
                            <p>Recibe una oferta real basada en los datos que has introducido.</p>
                        </div>
                        <div className="sellcar_page-body2-content-container-column">
                            <img src={icon2} alt="icon1" className="sellcar_icon" />
                            <h3>2. Visita una sucursal</h3>
                            <p>Si decides vender, reserva una cita en una sucursal cercana.</p>
                        </div>
                        <div className="sellcar_page-body2-content-container-column">
                            <img src={icon3} alt="icon1" className="sellcar_icon" />
                            <h3>3. Recibe tu dinero</h3>
                            <p>Vende tu coche en menos de una hora.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="sellcar_page-body2">
                <div className="sellcar_page-body2-content">
                    <h2>¿Cuáles son las ventajas?</h2>
                </div>
            </div>*/}
        </div>
    );
}
  
export default SellCar_page;
 