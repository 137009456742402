import { useState, forwardRef } from "react";
import { ReactComponent as SearchIcon } from './icons/search-magnifying-glass-svgrepo-com.svg';

const SearchBar3 = forwardRef(({ onSearch, query }, ref) => {
    const [searchQuery, setSearchQuery] = useState(query || "");

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onSearch(searchQuery);
        }
    };

    return (
        <div className="search-bar2">
            <input 
                ref={ref} 
                type="search" 
                placeholder="Marca, modelo..." 
                value={searchQuery} 
                onChange={handleInputChange} 
                onKeyPress={handleKeyPress} 
            />
            <button
                type="button"
                onClick={() => onSearch(searchQuery)}  // Aquí se asegura que se use el valor actualizado de searchQuery
            >
                <SearchIcon />
            </button>
        </div>
    );
});

export default SearchBar3;