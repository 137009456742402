import React, { useState, useRef, useEffect } from 'react';
import arrowIcon from './icons/caret-down-md-svgrepo-com.svg';

const MultiSelect2 = ({ options = [], placeholder, onChange, value = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setFilter('');
    }
  };

  const handleSelect = (option) => {
    const isSelected = value.includes(option.label);
    let newSelectedOptions;
    if (isSelected) {
      newSelectedOptions = value.filter(item => item !== option.label);
    } else {
      newSelectedOptions = [...value, option.label];
    }
    // Pass only the text labels to onChange
    onChange(newSelectedOptions.map(option => option.split(' ')[0]));
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const clearSelection = (e) => {
    e.stopPropagation();
    onChange([]);
    setIsOpen(false);
  };

  const filteredOptions = capitalizeAndSortOptions(options).filter(option =>
    option.label.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div ref={containerRef} className={`multi-select-container2 ${isOpen ? 'is-open' : ''}`}>
      <div 
        className={`multi-select-control2 ${value.length > 0 ? 'has-selection' : ''}`}
        onClick={handleToggle}
      >
        <div className={`multi-select-placeholder ${value.length > 0 ? 'placeholder-selected' : ''}`}>
          {placeholder}
        </div>
        <div className="multi-select-arrow2">
          <img src={arrowIcon} alt="Arrow" className={`arrow-icon2 ${isOpen ? 'open' : 'closed'}`} />
        </div>
      </div>
      {isOpen && (
        <div className="multi-select-menu2">
          <input
            ref={inputRef}
            type="text"
            className="multi-select-input"
            placeholder="Escriba para filtrar..."
            value={filter}
            onChange={handleFilterChange}
          />
          <div className="multi-select-options">
            {filteredOptions.map(option => (
              <div
                key={option.value}
                className={`multi-select-option ${value.includes(option.label) ? 'selected' : ''}`}
                onClick={() => handleSelect(option)}
              >
                {option.icon && (
                  <img src={option.icon} alt={option.label} className="multi-select-option-icon" />
                )}
                <span className={`multi-select-option-label ${value.includes(option.label) ? 'selected' : ''}`}>
                  {option.label}
                </span>
                <input
                  type="checkbox"
                  checked={value.includes(option.label)}
                  onChange={() => handleSelect(option)}
                  className="multi-select-checkbox"
                />
                <span className="custom-checkbox"></span>
              </div>
            ))}
          </div>
          <div className="multi-select-buttons">
            <button className="clear-all-button" onClick={clearSelection}>
              Borrar Selección
            </button>
            <button className="close-button" onClick={() => setIsOpen(false)}>
              Aceptar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const capitalizeAndSortOptions = (options) => {
  return options
    .map(option => ({
      ...option,
      label: option.label.charAt(0).toUpperCase() + option.label.slice(1).toLowerCase()
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export default MultiSelect2;
