import React from 'react';
import StarsIcon from './icons/stars-svgrepo-com.svg';

const ToggleSwitch = ({ checked, onChange }) => {
    return (
        <div className="switch-container">
            <label className="switch">
                <input type="checkbox" checked={checked} onChange={onChange} />
                <span className="slider round">
                    <span className="text search">Búsqueda</span>
                    <span className="text ai">AI</span>
                </span>
            </label>
        </div>
    );
};

export default ToggleSwitch;
