import { useEffect, useState } from "react";

import categoria_aux from './sellcar_photo1.png';

const Categories_menu = () => {

  return (
    <div className="categories_menu">
        <h3 className="titulo-marcas">Busca por <span className="titulo-destacado">estilo de vida</span></h3>

        <div className="grid-columns">

            <div className="grid-item">
                <h4 className="grid-title">Ciudad</h4>
            </div>

            <div className="grid-item">
                <h4 className="grid-title">Deportivo</h4>
            </div>

            <div className="grid-item">
                <h4 className="grid-title">Todoterreno</h4>
            </div>

            <div className="grid-item">
                <h4 className="grid-title">Familiar</h4>
            </div>

            <div className="grid-item">
                <h4 className="grid-title">Ecológico</h4>
            </div>

      </div>

    </div>
  );
}
  
export default Categories_menu;