import React, { useState } from 'react';
import CloseIcon from './icons/close-md-svgrepo-com.svg';
import StarsIcon from './icons/stars-svgrepo-com-2.svg';
import SearchBar2 from './SearchBar2';
import { brandOptions, modelOptions, countryOptions, transmissionOptions, fuelOptions, doorsOptions, seatsOptions, colorsOptions, priceOptions, yearOptions, mileageOptions } from './filterOptions';
import MultiSelect from './MultiSelect';
import OneSelect from './OneSelect';

const AdvancedFilters = ({ onClose, onSearch }) => {
  const [filters, setFilters] = useState({
    marca: [],
    transmission: [],
    fuel: [],
    doors: '',
    max_price: '',
    min_price: '',
    max_year: '',
    min_year: '',
    car_model: '',
    car_location_country: [],
    min_km: '',
    max_km: '',
    car_seats: '',
    car_color: []
  });

  const [query, setQuery] = useState('');

  const handleFilterChange = (selectedOptions, { name }) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: selectedOptions,
    }));
  };

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
  };

  const handleSearch = () => {
    const appliedFilters = Object.keys(filters).reduce((acc, key) => {
      if (filters[key] && filters[key].length > 0) acc[key] = filters[key];
      return acc;
    }, {});
    onSearch(query, appliedFilters);
    onClose();
  };

  const yearOptions = [];
  for (let year = 2024; year >= 1990; year--) {
    yearOptions.push({ value: year.toString(), label: year.toString() });
  }

  const filteredMinPriceOptions = priceOptions.filter(option => {
    if (filters.max_price) {
      return parseInt(option.value) <= parseInt(filters.max_price);
    }
    return true;
  });

  const filteredMaxPriceOptions = priceOptions.filter(option => {
    if (filters.min_price) {
      return parseInt(option.value) >= parseInt(filters.min_price);
    }
    return true;
  });

  const filteredMinYearOptions = yearOptions.filter(option => {
    if (filters.max_year) {
      return parseInt(option.value) <= parseInt(filters.max_year);
    }
    return true;
  });

  const filteredMaxYearOptions = yearOptions.filter(option => {
    if (filters.min_year) {
      return parseInt(option.value) >= parseInt(filters.min_year);
    }
    return true;
  });

  const filteredMinMileageOptions = mileageOptions.filter(option => {
    if (filters.max_km) {
      return parseInt(option.value) <= parseInt(filters.max_km);
    }
    return true;
  });

  const filteredMaxMileageOptions = mileageOptions.filter(option => {
    if (filters.min_km) {
      return parseInt(option.value) >= parseInt(filters.min_km);
    }
    return true;
  });

  const renderMultiSelect = (name, value, options, placeholder) => (
    <div className="select-container">
      {filters[name] && filters[name].length > 0 && <label className="placeholder-label">{placeholder}</label>}
      <MultiSelect
        options={options}
        value={value}
        onChange={selected => handleFilterChange(selected, { name })}
        placeholder={placeholder}
      />
    </div>
  );

  const renderOneSelect = (name, value, options, placeholder) => (
    <div className="select-container">
      {filters[name] && filters[name].length > 0 && <label className="placeholder-label">{placeholder}</label>}
      <OneSelect
        options={options}
        value={value}
        onChange={selected => handleFilterChange(selected, { name })}
        placeholder={placeholder}
      />
    </div>
  );

  const renderSelect = (name, value, options, placeholder, isSearchable = true) => (
    <div className="select-container">
      {filters[name] && <label className="placeholder-label">{placeholder}</label>}
      <select
        name={name}
        value={value}
        onChange={e => handleFilterChange([{ value: e.target.value }], { name })}
        className="select"
      >
        <option value="">{placeholder}</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );

  return (
    <div className="advanced-filters">
      <div className="filter-header">
        <h2 className='titulo-destacado'>Búsqueda avanzada</h2>
        <button className="close-button" onClick={onClose}>
          <img src={CloseIcon} alt="Cerrar" />
        </button>
      </div>
      
      <div className="searchbar-advanced-filters">
        <SearchBar2 onSearch={handleSearch} onQueryChange={handleQueryChange} />
      </div>

      <h3>Filtros principales</h3>
      <h4>País</h4>
      <h4>Marca</h4>
      <h4>Modelo</h4>
      <div className="filter">{renderMultiSelect("car_location_country", filters.car_location_country, countryOptions, "País")}</div>
      <div className="filter">{renderMultiSelect("marca", filters.marca, brandOptions, "Marca")}</div>
      <div className="filter">{renderMultiSelect("car_model", filters.car_model, modelOptions, "Modelo")}</div>
      <h4>Transmisión</h4>
      <h4>Combustible</h4>
      <h4></h4>
      <div className="filter">{renderOneSelect("transmission", filters.transmission, transmissionOptions, "Transmisión")}</div>
      <div className="filter">{renderMultiSelect("fuel", filters.fuel, fuelOptions, "Combustible")}</div>
      <h4></h4>

      <hr className="separator" />

      <h4>Precio</h4>
      <h4>Año</h4>
      <h4>Km</h4>
      <div className="filter-half">{renderOneSelect("min_price", filters.min_price, filteredMinPriceOptions, "De")}</div>
      <div className="filter-half">{renderOneSelect("max_price", filters.max_price, filteredMaxPriceOptions, "Hasta")}</div>
      <div className="filter-half">{renderOneSelect("min_year", filters.min_year, filteredMinYearOptions, "De")}</div>
      <div className="filter-half">{renderOneSelect("max_year", filters.max_year, filteredMaxYearOptions, "Hasta")}</div>
      <div className="filter-half">{renderOneSelect("min_km", filters.min_km, filteredMinMileageOptions, "De")}</div>
      <div className="filter-half">{renderOneSelect("max_km", filters.max_km, filteredMaxMileageOptions, "Hasta")}</div>
      
      <hr className="separator" />
      
      <h3>Filtros avanzados</h3>
      <h4>Puertas</h4>
      <h4>Plazas</h4>
      <h4>Color</h4>
      <div className="filter">{renderOneSelect("doors", filters.doors, doorsOptions, "Puertas")}</div>
      <div className="filter">{renderOneSelect("car_seats", filters.car_seats, seatsOptions, "Plazas")}</div>
      <div className="filter">{renderMultiSelect("car_color", filters.car_color, colorsOptions, "Color")}</div>
  
      <div className='advanced-filters-button'>
        <button className="ver-todo-button" onClick={handleSearch}>Buscar</button>
      </div>
      
    </div>
  );
};

export default AdvancedFilters;