import { useState, forwardRef, useEffect } from "react";
import { ReactComponent as SearchIcon } from './icons/search-magnifying-glass-svgrepo-com.svg';

const SearchBar2 = forwardRef(({ onSearch, query, onQueryChange }, ref) => {
    const [searchQuery, setSearchQuery] = useState(query || "");

    useEffect(() => {
        setSearchQuery(query || "");
    }, [query]);

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
        onQueryChange(e.target.value); // Pasar la consulta a AdvancedFilters
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onSearch(searchQuery);
        }
    };

    return (
        <div className="search-bar2">
            <input 
                ref={ref} 
                type="search" 
                placeholder="Palabra clave..." 
                value={searchQuery} 
                onChange={handleInputChange} 
                onKeyPress={handleKeyPress} 
            />
            <button
                type="button"
                onClick={() => onSearch(searchQuery)}  // Aquí se asegura que se use el valor actualizado de searchQuery
            >
                <SearchIcon />
            </button>
        </div>
    );
});

export default SearchBar2;
