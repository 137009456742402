import { useNavigate } from 'react-router-dom';
import carhistory_img from './carhistory_img3.webp';
import sellcar_s1 from './sellcar_photo1.png';
import icon1 from './icons/shield-check-svgrepo-com.svg';
import icon2 from './icons/clock-svgrepo-com.svg';
import icon3 from './icons/note-edit-svgrepo-com.svg';


function CarHistory_page() {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/carhistory');
        window.scrollTo({ top: 0});
    }

    return (
        <div className="sellcar_page">
            <div className="sellcar_page-body">
                <div className="text-content-carhistory">
                    <h1>Conoce la <span className="titulo-destacado">historia</span> <br />de un vehículo</h1>
                    <p>Obtén un informe para evitar malos acuerdos<br /> y descubrir si un vehículo es seguro.</p>
                    <button onClick={handleClick}>Obtener informe</button>
                </div>
                <div className="image-content-carhistory">
                    <img src={carhistory_img} alt="Car"></img>
                </div>
            </div>
            <div className="sellcar_page-body2">
                <div className="sellcar_page-body2-content">
                    <h2>Decide con seguridad</h2>
                    <div className="sellcar_page-body2-content-container">
                        <div className="sellcar_page-body2-content-container-column">
                            <img src={icon1} alt="icon1" className="sellcar_icon" />
                            <h3>Evita errores costosos</h3>
                            <p>Conoce el historial de cualquier vehículo para asegurarte de que no estás comprando una pérdida de dinero.</p>
                        </div>
                        <div className="sellcar_page-body2-content-container-column">
                            <img src={icon2} alt="icon1" className="sellcar_icon" />
                            <h3>Ahorra tiempo valioso</h3>
                            <p>Las pruebas de conducción llevan horas, así que elimina los inconvenientes antes de salir.</p>
                        </div>
                        <div className="sellcar_page-body2-content-container-column">
                            <img src={icon3} alt="icon1" className="sellcar_icon" />
                            <h3>Negocia un mejor acuerdo</h3>
                            <p>Una vez que hayas encontrado tu próximo vehículo, tendrás los datos para conseguir bajar ese precio.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="sellcar_page-body2">
                <div className="sellcar_page-body2-content">
                    <h2>¿Cuáles son las ventajas?</h2>
                </div>
            </div>*/}
        </div>
    );
}
  
export default CarHistory_page;
 