import alemaniaIcon from './icons/icon_flags/flag-de-svgrepo-com.svg';
import belgicaIcon from './icons/icon_flags/flag-be-svgrepo-com.svg';
import espanaIcon from './icons/icon_flags/flag-es-svgrepo-com.svg';
import franciaIcon from './icons/icon_flags/flag-wf-svgrepo-com.svg';
import italiaIcon from './icons/icon_flags/flag-it-svgrepo-com.svg';
import paisesBajosIcon from './icons/icon_flags/flag-nl-svgrepo-com.svg';
import portugalIcon from './icons/icon_flags/flag-pt-svgrepo-com.svg';
import reinoUnidoIcon from './icons/icon_flags/flag-gb-svgrepo-com.svg';

export const brandOptions = [
    { value: 'mercedes', label: 'Mercedes-Benz' },
    { value: 'bmw', label: 'BMW' },
    { value: 'audi', label: 'Audi' },
    { value: 'porsche', label: 'Porsche' },
    { value: 'volkswagen', label: 'Volkswagen' },
    { value: 'toyota', label: 'Toyota' },
    { value: 'mini', label: 'MINI' },
    { value: 'ford', label: 'Ford' },
    { value: 'volvo', label: 'Volvo' },
    
    { value: '9ff', label: '9ff' },
    { value: 'Abarth', label: 'Abarth' },
    { value: 'AC', label: 'AC' },
    { value: 'ACM', label: 'ACM' },
    { value: 'Acura', label: 'Acura' },
    { value: 'Aiways', label: 'Aiways' },
    { value: 'Aixam', label: 'Aixam' },
    { value: 'Alba Mobility', label: 'Alba Mobility' },
    { value: 'Alfa Romeo', label: 'Alfa Romeo' },
    { value: 'Alpina', label: 'Alpina' },
    { value: 'Alpine', label: 'Alpine' },
    { value: 'Amphicar', label: 'Amphicar' },
    { value: 'Angelelli Automobili', label: 'Angelelli Automobili' },
    { value: 'Ariel Motor', label: 'Ariel Motor' },
    { value: 'Artega', label: 'Artega' },
    { value: 'Aspark', label: 'Aspark' },
    { value: 'Aspid', label: 'Aspid' },
    { value: 'Aston Martin', label: 'Aston Martin' },
    { value: 'Aurus', label: 'Aurus' },
    { value: 'Austin', label: 'Austin' },
    { value: 'Austin-Healey', label: 'Austin-Healey' },
    { value: 'Autobianchi', label: 'Autobianchi' },
    { value: 'BAIC', label: 'BAIC' },
    { value: 'Bedford', label: 'Bedford' },
    { value: 'Bellier', label: 'Bellier' },
    { value: 'Bentley', label: 'Bentley' },
    { value: 'Boldmen', label: 'Boldmen' },
    { value: 'Bolloré', label: 'Bolloré' },
    { value: 'Borgward', label: 'Borgward' },
    { value: 'Brilliance', label: 'Brilliance' },
    { value: 'Bristol', label: 'Bristol' },
    { value: 'Brute', label: 'Brute' },
    { value: 'Bugatti', label: 'Bugatti' },
    { value: 'Buick', label: 'Buick' },
    { value: 'BYD', label: 'BYD' },
    { value: 'Cadillac', label: 'Cadillac' },
    { value: 'Caravans-Wohnm', label: 'Caravans-Wohnm' },
    { value: 'Carver', label: 'Carver' },
    { value: 'Casalini', label: 'Casalini' },
    { value: 'Caterham', label: 'Caterham' },
    { value: 'Cenntro', label: 'Cenntro' },
    { value: 'Changhe', label: 'Changhe' },
    { value: 'Chatenet', label: 'Chatenet' },
    { value: 'Chery', label: 'Chery' },
    { value: 'Chevrolet', label: 'Chevrolet' },
    { value: 'Chrysler', label: 'Chrysler' },
    { value: 'Cirelli', label: 'Cirelli' },
    { value: 'Citroen', label: 'Citroen' },
    { value: 'CityEL', label: 'CityEL' },
    { value: 'Corvette', label: 'Corvette' },
    { value: 'CUPRA', label: 'CUPRA' },
    { value: 'Dacia', label: 'Dacia' },
    { value: 'Daewoo', label: 'Daewoo' },
    { value: 'DAF', label: 'DAF' },
    { value: 'Daihatsu', label: 'Daihatsu' },
    { value: 'Daimler', label: 'Daimler' },
    { value: 'Dallara', label: 'Dallara' },
    { value: 'Dangel', label: 'Dangel' },
    { value: 'De la Chapelle', label: 'De la Chapelle' },
    { value: 'De Tomaso', label: 'De Tomaso' },
    { value: 'Delorean', label: 'Delorean' },
    { value: 'Devinci Cars', label: 'Devinci Cars' },
    { value: 'DFSK', label: 'DFSK' },
    { value: 'Dodge', label: 'Dodge' },
    { value: 'Donkervoort', label: 'Donkervoort' },
    { value: 'DR Automobiles', label: 'DR Automobiles' },
    { value: 'DS Automobiles', label: 'DS Automobiles' },
    { value: 'Dutton', label: 'Dutton' },
    { value: 'e.GO', label: 'e.GO' },
    { value: 'Econelo', label: 'Econelo' },
    { value: 'Edran', label: 'Edran' },
    { value: 'Elaris', label: 'Elaris' },
    { value: 'Embuggy', label: 'Embuggy' },
    { value: 'EMC', label: 'EMC' },
    { value: 'Estrima', label: 'Estrima' },
    { value: 'Evetta', label: 'Evetta' },
    { value: 'EVO', label: 'EVO' },
    { value: 'ferrari', label: 'Ferrari' },
    { value: 'Fiat', label: 'Fiat' },
    { value: 'FISKER', label: 'FISKER' },
    { value: 'Forthing', label: 'Forthing' },
    { value: 'Foton', label: 'Foton' },
    { value: 'Gac Gonow', label: 'Gac Gonow' },
    { value: 'Galloper', label: 'Galloper' },
    { value: 'Gappy', label: 'Gappy' },
    { value: 'GAZ', label: 'GAZ' },
    { value: 'GEM', label: 'GEM' },
    { value: 'GEMBALLA', label: 'GEMBALLA' },
    { value: 'Genesis', label: 'Genesis' },
    { value: 'Giana', label: 'Giana' },
    { value: 'Gillet', label: 'Gillet' },
    { value: 'Giotti Victoria', label: 'Giotti Victoria' },
    { value: 'GMC', label: 'GMC' },
    { value: 'Goupil', label: 'Goupil' },
    { value: 'Great Wall', label: 'Great Wall' },
    { value: 'Grecav', label: 'Grecav' },
    { value: 'GTA', label: 'GTA' },
    { value: 'GWM', label: 'GWM' },
    { value: 'Haima', label: 'Haima' },
    { value: 'Hanomag', label: 'Hanomag' },
    { value: 'Haval', label: 'Haval' },
    { value: 'Hawtai', label: 'Hawtai' },
    { value: 'Heinkel', label: 'Heinkel' },
    { value: 'Hillman', label: 'Hillman' },
    { value: 'Hispano Suiza', label: 'Hispano Suiza' },
    { value: 'Holden', label: 'Holden' },
    { value: 'Honda', label: 'Honda' },
    { value: 'Hongqi', label: 'Hongqi' },
    { value: 'Hummer', label: 'Hummer' },
    { value: 'Hymer', label: 'Hymer' },
    { value: 'Hyundai', label: 'Hyundai' },
    { value: 'Ineos', label: 'Ineos' },
    { value: 'Infiniti', label: 'Infiniti' },
    { value: 'Innocenti', label: 'Innocenti' },
    { value: 'Iso', label: 'Iso' },
    { value: 'Isuzu', label: 'Isuzu' },
    { value: 'Iveco', label: 'Iveco' },
    { value: 'JAC', label: 'JAC' },
    { value: 'Jaguar', label: 'Jaguar' },
    { value: 'Jeep', label: 'Jeep' },
    { value: 'Jetour', label: 'Jetour' },
    { value: 'JMC', label: 'JMC' },
    { value: 'Kaipan', label: 'Kaipan' },
    { value: 'Karabag', label: 'Karabag' },
    { value: 'Keinath', label: 'Keinath' },
    { value: 'KIA', label: 'KIA' },
    { value: 'KTM', label: 'KTM' },
    { value: 'Lada', label: 'Lada' },
    { value: 'Lamborghini', label: 'Lamborghini' },
    { value: 'Lancia', label: 'Lancia' },
    { value: 'Land Rover', label: 'Land Rover' },
    { value: 'LDV', label: 'LDV' },
    { value: 'LEVC', label: 'LEVC' },
    { value: 'Lexani', label: 'Lexani' },
    { value: 'Lexus', label: 'Lexus' },
    { value: 'Li Auto', label: 'Li Auto' },
    { value: 'Lifan', label: 'Lifan' },
    { value: 'Ligier', label: 'Ligier' },
    { value: 'Lincoln', label: 'Lincoln' },
    { value: 'Lotus', label: 'Lotus' },
    { value: 'Lucid', label: 'Lucid' },
    { value: 'Mahindra', label: 'Mahindra' },
    { value: 'MAN', label: 'MAN' },
    { value: 'Maserati', label: 'Maserati' },
    { value: 'Maxus', label: 'Maxus' },
    { value: 'Mazda', label: 'Mazda' },
    { value: 'MCLaren', label: 'MCLaren' },
    { value: 'Mega', label: 'Mega' },
    { value: 'Mercedes', label: 'Mercedes' },
    { value: 'MG', label: 'MG' },
    { value: 'Microcar', label: 'Microcar' },
    { value: 'MINI', label: 'MINI' },
    { value: 'Mitsubishi', label: 'Mitsubishi' },
    { value: 'Mitsubishi Fuso', label: 'Mitsubishi Fuso' },
    { value: 'Mitsubishi Forklift', label: 'Mitsubishi Forklift' },
    { value: 'Morgan', label: 'Morgan' },
    { value: 'Morris', label: 'Morris' },
    { value: 'Moskvich', label: 'Moskvich' },
    { value: 'MPM', label: 'MPM' },
    { value: 'Mullen', label: 'Mullen' },
    { value: 'Nash', label: 'Nash' },
    { value: 'Next', label: 'Next' },
    { value: 'NIO', label: 'NIO' },
    { value: 'Nissan', label: 'Nissan' },
    { value: 'NSU', label: 'NSU' },
    { value: 'OM', label: 'OM' },
    { value: 'Oldsmobile', label: 'Oldsmobile' },
    { value: 'Oltcit', label: 'Oltcit' },
    { value: 'Opel', label: 'Opel' },
    { value: 'Osca', label: 'Osca' },
    { value: 'Pagani', label: 'Pagani' },
    { value: 'Panhard', label: 'Panhard' },
    { value: 'Peugeot', label: 'Peugeot' },
    { value: 'Piaggio', label: 'Piaggio' },
    { value: 'Polestar', label: 'Polestar' },
    { value: 'Pontiac', label: 'Pontiac' },
    { value: 'Porsche', label: 'Porsche' },
    { value: 'Proton', label: 'Proton' },
    { value: 'RAM', label: 'RAM' },
    { value: 'Reliant', label: 'Reliant' },
    { value: 'Renault', label: 'Renault' },
    { value: 'Rezvani', label: 'Rezvani' },
    { value: 'Rimac', label: 'Rimac' },
    { value: 'Rivian', label: 'Rivian' },
    { value: 'Rolls Royce', label: 'Rolls Royce' },
    { value: 'Rover', label: 'Rover' },
    { value: 'Saab', label: 'Saab' },
    { value: 'Saehan', label: 'Saehan' },
    { value: 'Saihatsu', label: 'Saihatsu' },
    { value: 'Saleen', label: 'Saleen' },
    { value: 'Samsung', label: 'Samsung' },
    { value: 'Santana', label: 'Santana' },
    { value: 'Saturn', label: 'Saturn' },
    { value: 'Scion', label: 'Scion' },
    { value: 'SEAT', label: 'SEAT' },
    { value: 'Shuanghuan', label: 'Shuanghuan' },
    { value: 'Škoda', label: 'Škoda' },
    { value: 'Smart', label: 'Smart' },
    { value: 'Soueast', label: 'Soueast' },
    { value: 'Spectre', label: 'Spectre' },
    { value: 'Spyker', label: 'Spyker' },
    { value: 'SsangYong', label: 'SsangYong' },
    { value: 'Steyr', label: 'Steyr' },
    { value: 'Studebaker', label: 'Studebaker' },
    { value: 'Subaru', label: 'Subaru' },
    { value: 'Suzuki', label: 'Suzuki' },
    { value: 'Talbot', label: 'Talbot' },
    { value: 'Tata', label: 'Tata' },
    { value: 'Tazzari', label: 'Tazzari' },
    { value: 'Tesla', label: 'Tesla' },
    { value: 'Think', label: 'Think' },
    { value: 'Togg', label: 'Togg' },
    { value: 'Toyota', label: 'Toyota' },
    { value: 'Trabant', label: 'Trabant' },
    { value: 'Triumph', label: 'Triumph' },
    { value: 'TVR', label: 'TVR' },
    { value: 'UAZ', label: 'UAZ' },
    { value: 'Venturi', label: 'Venturi' },
    { value: 'Volkswagen', label: 'Volkswagen' },
    { value: 'Volvo', label: 'Volvo' },
    { value: 'Vortex', label: 'Vortex' },
    { value: 'Wallys', label: 'Wallys' },
    { value: 'Wartburg', label: 'Wartburg' },
    { value: 'Wiesmann', label: 'Wiesmann' },
    { value: 'XEV', label: 'XEV' },
    { value: 'XPeng', label: 'XPeng' },
    { value: 'Yes', label: 'Yes' },
    { value: 'Zanella', label: 'Zanella' },
    { value: 'Zastava', label: 'Zastava' },
    { value: 'ZAZ', label: 'ZAZ' },
    { value: 'Zenvo', label: 'Zenvo' },
    { value: 'Zhidou', label: 'Zhidou' },
    { value: 'Zotye', label: 'Zotye' },

  ];
  
  export const modelOptions = [
    { value: 'alemania', label: 'Alemania' },
    { value: 'belgica', label: 'Belgica' },
    { value: 'españa', label: 'España' },
  ];
  
  export const countryOptions = [
    { value: 'alemania', label: 'Alemania', icon: alemaniaIcon },
    { value: 'belgica', label: 'Belgica', icon: belgicaIcon },
    { value: 'españa', label: 'España', icon: espanaIcon },
    { value: 'francia', label: 'Francia', icon: franciaIcon },
    { value: 'italia', label: 'Italia', icon: italiaIcon },
    { value: 'paises bajos', label: 'Paises Bajos', icon: paisesBajosIcon },
    { value: 'portugal', label: 'Portugal', icon: portugalIcon },
    { value: 'reino unido', label: 'Reino Unido', icon: reinoUnidoIcon },
  ];

  export const transmissionOptions = [
    { value: 'manual', label: 'Manual' },
    { value: 'automatico', label: 'Automático' },
  ];
  
  export const fuelOptions = [
    { value: 'gasolina', label: 'Gasolina' },
    { value: 'diesel', label: 'Diésel' },
    { value: 'electrico', label: 'Eléctrico' },
    { value: 'hibrido', label: 'Híbrido' },
  ];
  
  export const doorsOptions = [
    { value: '2', label: '2 puertas' },
    { value: '3', label: '3 puertas' },
    { value: '4', label: '4 puertas' },
    { value: '5', label: '5 puertas' },
  ];

  export const seatsOptions = [
    { value: '2', label: '2 plazas' },
    { value: '3', label: '3 plazas' },
    { value: '4', label: '4 plazas' },
    { value: '5', label: '5 plazas' },
  ];

  export const colorsOptions = [
    { value: 'negro', label: 'Negro' },
    { value: 'blanco', label: 'Blanco' },
    { value: 'rojo', label: 'Rojo' },
    { value: 'gris', label: 'Gris' },
  ];

  export const priceOptions = [
    { value: '500', label: '500' },
    { value: '1000', label: '1.000' },
    { value: '1500', label: '1.500' },
    { value: '2000', label: '2.000' },
    { value: '2500', label: '2.500' },
    { value: '3000', label: '3.000' },
    { value: '4000', label: '4.000' },
    { value: '5000', label: '5.000' },
    { value: '6000', label: '6.000' },
    { value: '7000', label: '7.000' },
    { value: '8000', label: '8.000' },
    { value: '9000', label: '9.000' },
    { value: '10000', label: '10.000' },
    { value: '15000', label: '15.000' },
    { value: '20000', label: '20.000' },
    { value: '30000', label: '30.000' },
    { value: '40000', label: '40.000' },
    { value: '50000', label: '50.000' },
  ];
  

  export const mileageOptions = [
    { value: '500', label: '500' },
    { value: '1000', label: '1.000' },
    { value: '1500', label: '1.500' },
    { value: '2000', label: '2.000' },
    { value: '2500', label: '2.500' },
    { value: '3000', label: '3.000' },
    { value: '4000', label: '4.000' },
    { value: '5000', label: '5.000' },
    { value: '6000', label: '6.000' },
    { value: '7000', label: '7.000' },
    { value: '8000', label: '8.000' },
    { value: '9000', label: '9.000' },
    { value: '10000', label: '10.000' },
    { value: '15000', label: '15.000' },
    { value: '20000', label: '20.000' },
    { value: '30000', label: '30.000' },
    { value: '40000', label: '40.000' },
    { value: '50000', label: '500.000' },
  ];
  