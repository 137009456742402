import { useEffect, useState } from "react";

const useFetch = (url, timeout = 5000) => {
    const [data, setData] = useState({ total: 0, cars: [] });
    const [isPending, setIsPending] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!url) return;

        setIsPending(true);
        setData({ total: 0, cars: [] });
        setError(null);

        const fetchData = async () => {
            const startTime = Date.now();

            try {
                const fetchPromise = fetch(url).then(response => {
                    if (!response.ok) {
                        throw new Error('could not fetch');
                    }
                    return response.json();
                });

                const timeoutPromise = new Promise((_, reject) =>
                    setTimeout(() => reject(new Error('Request timed out')), timeout)
                );

                const result = await Promise.race([fetchPromise, timeoutPromise]);

                const elapsedTime = Date.now() - startTime;
                const remainingTime = Math.max(0, 500 - elapsedTime);

                setTimeout(() => {
                    setData(result);
                    setIsPending(false);
                    setError(null);
                }, remainingTime);
            } catch (err) {
                const elapsedTime = Date.now() - startTime;
                const remainingTime = Math.max(0, 500 - elapsedTime);

                setTimeout(() => {
                    setIsPending(false);
                    setError(err.message);
                }, remainingTime);
            }
        };

        fetchData();
    }, [url, timeout]);

    return { data, isPending, error };
};

export default useFetch;
